import React from 'react';

function Projects() {
  return (
    <div className="section">
      <h2>Side Projects</h2>
      <p>Here are a few of the projects I've worked on:</p>
      <ul>
        <li><strong>Project 1:</strong> A Browser Extension that allows users to set duration for Google Tasks in Google Calendar view.</li>
        <li><strong>Project 2:</strong> A mobile app for /swiper/.</li>
        <li><strong>Project 3:</strong> A blog to share my learnings and experiences.</li>
      </ul>
    </div>
  );
}

export default Projects;
