import React from 'react';

function About() {
  return (
    <div className="section">
      <h2>About Me</h2>
      <p>
        I am a Product Manager with a deep passion for creating innovative products that make a difference.
        In my spare time, I enjoy developing applications, trying out new cuisines, and exploring nature in various parks.
        My journey is fueled by curiosity and a desire to continuously learn and grow.
      </p>
    </div>
  );
}

export default About;
