import React from 'react';

function Contact() {
  return (
    <div className="section">
      <h2>Contact</h2>
      <p>If you'd like to get in touch, please fill out the form below:</p>
      <form className="contact-form">
        <input type="text" name="name" placeholder="Your Name" required />
        <input type="email" name="email" placeholder="Your Email" required />
        <textarea name="message" placeholder="Your Message" rows="5" required></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default Contact;
