import React from 'react';
import './styles/Home.css';

function Home() {
  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title">Building for <span className="highlight">humans.</span></h1>
          <p className="hero-subtitle">
            Currently, I'm a <strong>Product Manager at Just Eat Takeaway.
            </strong> From my Bachelors in <strong>Innovative Development </strong> I built my expertise in <strong>solving real problems through innovative solutions</strong> through <strong> an agile and iterative process</strong>.
            With my Ambitious, Curious spirit and problem-solving mindset, I've built impactful products utilizing various technologies.
          </p>
          <a href="projects" className="cta-button">My Projects</a>
        </div>
        <div className="hero-image">
          <img src="https://res.cloudinary.com/dnttxluaf/image/upload/v1722174731/Saly-19_iwbqdq.png" alt="Illustration" />
        </div>
      </section>
      <section className="skills">
        <div className="skills-content">
          <h2 className="skills-title">Building products for humans</h2>
          <p className="skills-subtitle">
            With my broad IT background, I bring a lean mindset for building products fast. My abilities to effectively identify the right problems and innovative mindset sets me apart me to build products that understand and enhance user experiences.
          </p>
          <div className="skills-grid">
            <div className="skill-card">
              <img src="https://res.cloudinary.com/dnttxluaf/image/upload/v1722180204/target_cwebh0.png" alt="Product Strategy" />
              <h3>Product Strategy</h3>
              <p>Roadmapping, A/B Tests, Project Management</p>
            </div>
            <div className="skill-card">
              <img src="https://res.cloudinary.com/dnttxluaf/image/upload/v1722179870/web-design_el2xmp.png" alt="Product Design" />
              <h3>Product Design</h3>
              <p>User Stories, User Flows, Wireframes, Persona Writing</p>
            </div>
            <div className="skill-card">
              <img src="https://res.cloudinary.com/dnttxluaf/image/upload/v1722180573/research_1_tkxcmp.png" alt="Market & User Research" />
              <h3>Market & User Research</h3>
              <p>Market Analysis, Experiment Design, Usability Testing</p>
            </div>
            <div className="skill-card">
              <img src="https://res.cloudinary.com/dnttxluaf/image/upload/v1722180731/monitor_vek2ss.png" alt="Analytics & Programming" />
              <h3>Analytics & Programming</h3>
              <p>Software Development and Management</p>
            </div>
          </div>
        </div>
      </section>
      <div className="footnote">
        <a href="https://www.flaticon.com/free-icons/web-design" title="web design icons">Web design icons created by Design Circle - Flaticon</a>
      </div>
    </div>
  );
}

export default Home;